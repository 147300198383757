$text-color: rgb(227, 208, 181);

html {
  min-height: 100%;
  /* make sure it is at least as tall as the viewport */
}

body {
  height: 100%;
  /* force the BODY element to match the height of the HTML element */
}

#root {
  height: 100%;
}

.App {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
  text-align: center;
  direction: rtl;
  font-family: 'Varela Round', sans-serif;
  height: 100%;

  .background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../public/beach_background.jpeg');
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.8;
  }

  .Menu {
    .open-menu-button {
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 11;

      .line-1,
      .line-2,
      .line-3 {
        height: 5px;
        width: 34px;
        background-color: $text-color;
        border-radius: 20px;
        margin-top: 5px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
        transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out,
          box-shadow 0.8s ease-in-out, background-color 0.8s ease-in-out;

        &.open {
          box-shadow: 0px 0px 0px;
          background-color: white;
        }
      }

      .line-1.open {
        transform: rotate(45deg) translate(5px, 10px);
      }

      .line-2.open {
        opacity: 0;
      }

      .line-3.open {
        transform: rotate(-45deg) translate(4px, -10px);
      }
    }

    .menu-container {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba($text-color, 0.9);
      transform: translateY(-100%);
      transition: transform 0.8s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 10;

      &.open {
        transform: translateY(0);
      }

      .menu-item {
        color: white;
        font-size: 30px;
        text-shadow: 1px 1px 2px black;
        margin: 20px 0;
      }
    }
  }

  .img-container {
    opacity: 1;
    pointer-events: all;
    display: block;
    background: white;
    max-width: 100vw;

    #welcome-img {
      content: url('../public/welcome.svg');
      max-width: 100%;
    }
  }

  .thanks-container {
    opacity: 1;
    pointer-events: all;
    display: block;
    background: white;
    max-width: 100vw;

    #thanks-img {
      max-width: 100%;
    }
  }

  .seperators-container {
    #seperator-1-img {
      content: url('../public/seperator-1.svg');
      max-width: 100%;
    }

    #seperator-2-img {
      content: url('../public/seperator-2.svg');
      max-width: 100%;
    }
    #seperator-3-img {
      content: url('../public/seperator-3.svg');
      max-width: 100%;
    }
  }

  .page {
    min-height: 100vh;
    opacity: 1;
    pointer-events: all;
    display: block;

    .submit-button {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 15px;
      background-color: transparent;
      color: black;
      // border: none;
      text-align: center;
      cursor: pointer;
    }

    .title {
      color: $text-color;
      font-family: 'Dancing Script', cursive;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 42px;
      margin-top: 5px;
      text-shadow: 1px 1px 2px black;
      direction: ltr;
    }

    &.home {
      .title {
        height: 600px;
        display: flex;
        flex-direction: column;
        font-size: 90px;
      }

      .text {
        color: $text-color;
        font-family: 'Dancing Script', cursive;
        justify-content: center;
        align-items: center;
        text-shadow: 1px 1px 2px black;
      }

      .dates {
        font-size: 45px;
      }
    }

    &.entrance {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      padding-top: 6px;
      height: 100%;
      width: 100%;

    }

    &.schedule {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      padding-top: 6px;

      .schedule-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .emoji {
          font-size: 50px;
          margin-left: 20px;
        }

        .text {
          font-size: 26px;
        }
      }

      .schedule-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .point {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 4px solid $text-color;
          margin-left: 20px;
        }

        .item-emoji {
          font-size: 40px;
          margin-left: 10px;
          width: 75px;
        }

        .item-text {
          font-size: 18px;
          text-align: right;
          width: 250px;
        }

        .line-to-next {
          position: absolute;
          bottom: -18px;
          right: 8px;
          width: 4px;
          height: 38px;
          background-color: $text-color;
        }
      }

      .separator {
        width: 100%;
        height: 2px;
        background-color: $text-color;
        margin: 20px auto;
      }
    }

    &.what-to-bring {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      padding-top: 6px;

      .what-to-bring-container {
        margin-top: 10px;

        .add-todo-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;

          .add-todo-input {
            border: none;
            outline: none;
            border-bottom: 1px solid $text-color;
            background-color: transparent;
            width: 80%;
            font-size: 20px;
            font-family: 'Varela Round', cursive;
          }
        }

        .todo-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 20px 0;

          .todo-checkbox {
            margin-left: 14px;
          }

          .todo-title {
            text-align: right;
            font-size: 18px;

            &.done {
              // text-decoration: line-through;
              color: #6c757d;
            }

            .todoSubTitleSpecial {
              font-size: 14px;
            }
          }

          .delete-button {
            position: absolute;
            left: 20px;
          }
        }
      }
    }

    &.information {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      padding-top: 6px;
      height: 100%;
      width: 100%;

      .add-shuttle-container{
          padding: 20px;
          background-color: #f7f7f7;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        
        
        label {
          display: block;
          margin-bottom: 10px;
        }
        
        input[type="text"],
        select {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }

      .useful-update-container {
        margin-top: 5%;
      }

      .section-container {
        min-height: 25%;

        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 60px;
      }

      .info-title {
        font-size: 28px;
        margin-top: 20px;
        min-width: 100%;
        text-align: right;
      }

      .shuttle-info-title{
        font-size: 25px;
        margin-top: 20px;
        min-width: 100%;
        text-align: right;
      }

      .text {
        min-width: 100%;
        text-align: right;

        .title-in-text{
        text-align: center;
        font-weight: 600;
        }
        div {
          margin-top: 10px;
        }

        .side-note-container {
          div {
            font-size: 14px;

          }
        }
      }

      .separator {
        width: 100%;
        height: 2px;
        background-color: $text-color;
        margin: 20px auto;
      }
    }

    &.comments {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      padding-top: 6px;
      height: 100%;
      width: 100%;

      .comments-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        direction: rtl; /* Right-to-left direction */
      }
      
      .comments-list {
        border: 1px solid #ccc;
        padding: 20px;
        border-radius: 5px;
        margin-top: 20px;
        background-color: #f7f7f7;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden; /* Hide overflow beyond 70vh */
      }
      .comments-table-wrapper {
        max-height: 100vh;
        overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
      }
      
      .comments-table {
        width: 100%;
        border-collapse: collapse;
      }
      .comments-table td {
        padding: 10px;
        text-align: right; /* Align content to the right for RTL */
      }
      
      .comment-title {
        margin-left: 10px;
        min-width: 20%;
      }

      .comment-content {
        margin-left: 20px; /* Add some margin to the left for spacing */
      }
      
      .comment-name {
        font-weight: bold;
      }
      
      .comment-time {
        color: #777; /* Color for the timestamp */
        font-size: 12px;
        direction: ltr;
      }
      
      .comment-text {
        margin: 0;
        font-size: 14px;
      }
      
      /* Style the last comment-item to remove the bottom border */
      .comment-item:last-child {
        border-bottom: none;
      }
      
      /* Responsive styles for mobile devices */
      @media (max-width: 768px) {
        .comments-container {
          padding: 10px;
          direction: rtl;
        }
      
        .comments-list {
          padding: 10px;
        }
        .comments-table td {
          text-align: right; /* Align content to the left for mobile */
        }
        .comment-item {
          margin-bottom: 10px;
          display: flex; /* Stack name and timestamp vertically */
          align-items: flex-start; /* Align name and timestamp to the left */
        }
      
        .comment-name {
          margin-bottom: 0; /* Remove margin-bottom for name in mobile */
        }
      }

      /* Additional styling for input and button, customize as needed */
      input[type="text"] {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      button {
        padding: 10px 20px;
        background-color: rgb(227, 208, 181);
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      button:hover {
        background-color: rgb(207, 188, 161);
      }
    }
  }
}